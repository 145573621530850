import { useFlags } from '@atlaskit/flag';
import { ICustomer, IStoredCustomer } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { IUser } from 'core/api/users/users-api-interface';
import UsersApiService from 'core/api/users/users-api.service';
import { Permissions } from 'core/constants/permissions';
import { multiTimestampsToSeconds } from 'core/utilities/date-helpers';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { CustomersSlice } from 'modules/customers/customers-slice';
import { UsersSlice } from 'modules/users/users-slice';
import { createContext, useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthState } from './AuthProvider';
export const FirestoreContext = createContext({});

export const useFirestoreState = () => {
  const state = useContext(FirestoreContext);
  return state;
};

export const FirestoreProvider = ({ children }: any) => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const { userPermissions } = useAuthState();

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag(
        'An critical error occurred',
        'Could not retrieve data critical to the application, please refresh.',
        flags
      );
    },
    [flags]
  );

  const createUsersSubscription = useCallback(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IUser>) => {
      dispatch(UsersSlice.resetUserState());
      const users: IUser[] = [];
      querySnapshot.forEach((userSnapShot) => {
        users.push(userSnapShot.data());
      });
      dispatch(UsersSlice.addMultiple(users));
    };

    return UsersApiService.subscribeToAllUsers(handleSnapshot, handleSubscriptionError);
  }, [dispatch, handleSubscriptionError]);

  const createCustomersSubscription = useCallback(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<ICustomer>) => {
      dispatch(CustomersSlice.resetState());
      const list: IStoredCustomer[] = [];
      querySnapshot.forEach((snap) => {
        const data = snap.data();
        const { lastUpdated, createdAt, statusUpdatedAt } = data;
        list.push({
          uid: data.uid,
          fullName: data.fullName,
          address: data.address,
          age: data.age,
          status: data.status,
          bookingStatus: data.bookingStatus,
          callback: data.callback,
          leadType: data.leadType,
          statusUpdatedBy: data.statusUpdatedBy,
          mvfCounty: data.mvfCounty,
          ...multiTimestampsToSeconds({
            lastUpdated,
            createdAt,
            statusUpdatedAt,
          }),
        });
      });
      list.sort((a, b) => (b.lastUpdated ?? 1) - (a.lastUpdated ?? 1));
      dispatch(CustomersSlice.addMultiple(list));
    };

    return CustomersApiService.subscribeToLatestCustomers(handleSnapshot, handleSubscriptionError);
  }, [dispatch, handleSubscriptionError]);

  useEffect(() => {
    const subscriptionMatrix = [
      {
        key: 'users',
        permissionsRequired: [Permissions.USERS_GET],
        func: createUsersSubscription,
        reset: UsersSlice.resetUserState,
      },
      {
        key: 'customer',
        permissionsRequired: [Permissions.CACHE_CUSTOMERS],
        func: createCustomersSubscription,
        reset: CustomersSlice.resetState,
      },
    ];

    const subscriptions: { unsubscribe: Unsubscribe; resetState?: any }[] = [];
    if (userPermissions) {
      subscriptionMatrix.forEach((sub) => {
        if (sub.permissionsRequired.every((permission) => userPermissions.includes(permission))) {
          subscriptions.push({ unsubscribe: sub.func(), resetState: sub.reset() });
        }
      });
      return () => {
        subscriptions.forEach((sub) => {
          sub.unsubscribe();
          dispatch(sub.resetState);
        });
      };
    }
  }, [createUsersSubscription, createCustomersSubscription, userPermissions, dispatch]);

  return <FirestoreContext.Provider value={{}}>{children}</FirestoreContext.Provider>;
};
